// Initialize
@import "../sass/variables";

.jssocials-share-link {
  padding: .4em .5em;
  border-radius: 0.3em;
  border: 2px solid $secondary-color;
  color: $secondary-color;
  background-color: var(--dark-color);
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.jssocials-share-link:hover,
.jssocials-share-link:focus,
.jssocials-share-link:active {
  background-color: $theme-color;
  color: #fff !important;
}

.jssocials-share-count-box {
  position: relative;
  height: 2.5em;
  padding: 0 .3em;
  margin-left: 0.4em;
  background: #f5f5f5;
  border-radius: 0.3em;
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.jssocials-share-count-box:hover {
  background: #e8e8e8;
}

.jssocials-share-count-box:hover:after {
  border-color: transparent #e8e8e8 transparent transparent;
}

.jssocials-share-count-box:after {
  content: "";
  display: block;
  position: absolute;
  top: 0.75em;
  left: -0.4em;
  width: 0;
  height: 0;
  border-width: 0.5em 0.5em 0.5em 0;
  border-style: solid;
  border-color: transparent #f5f5f5 transparent transparent;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
  transition: background 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
}

.jssocials-share-count-box .jssocials-share-count {
  line-height: 2.5em;
  color: #acacac;
}

.jssocials-plain-facebook .jssocials-share-link,
.jssocials-plain-twitter .jssocials-share-link,
.jssocials-plain-instagram .jssocials-share-link,
.jssocials-plain-googleplus .jssocials-share-link {
  background: transparent;
}

.jssocials-plain-facebook .jssocials-share-link:hover,
.jssocials-plain-twitter .jssocials-share-link:hover,
.jssocials-plain-instagram .jssocials-share-link:hover,
.jssocials-plain-googleplus .jssocials-share-link:hover {
  background: transparent;
}